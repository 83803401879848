<template>
  <v-container id="nota-cata">
    <v-row
      align="center"
    >
      <v-col
        sm="12"
        md="6"
        class="offset-md-3"
      >
        <v-img
          :src="require('@/assets/mezcal/' + value.image)"
          margin-bottom="30px"
          contain
        />
        <h1 class="display-2 font-weight-light mb-5 text-center mt-5">
          {{ value.name }}
        </h1>
        <p
          v-html="value.imageText"
        />
      </v-col>
    </v-row>
    <div>
      <h1 class="display-2 font-weight-light mb-5 nota-cata-title">
        {{ value.tastingNote }}
      </h1>
      <v-row>
        <cata-item
          v-for="note in value.cataNotes"
          :key="note.title"
          :value="note"
        />
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'NotaCata',
    components: {
      CataItem: () => import('@/components/base/CataItem'),
    },
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style scoped>
  .logo-background {
    background-color: #3F5465;
  }
  .logo-background-white {
    background-color: #FFFFFF;
    margin-top: 2000px;
    margin-bottom: 20005px;
  }
  .bottom-spacing {
    margin-bottom: 50px;
  }
  .nota-cata-title {
    margin-top: 25px;
    margin-bottom: 25px;
  }
</style>
